export const DownloadableFileType = {
	Pdf: "pdf",
	Csv: "csv",
} as const

export type DownloadableFileType =
	(typeof DownloadableFileType)[keyof typeof DownloadableFileType]

export type DownloadableFileOption = {
	name: string
	value: DownloadableFileType
}
