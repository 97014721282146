// React
import { Suspense, useState, useRef } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// Dates
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// PDF
import { generatePDF, PDFViewWrapper, getPdfFileName } from "@/lib/pdf"

// Translations
import { useTrans } from "@/i18n"

// UI
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Button } from "@/components/Button"
import { PayoutsTablePrint } from "./payments/_components/PayoutsTablePrint"
import { PayoutsTableWeb } from "./payments/_components/PayoutsTableWeb"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"
import { LoadingScreen } from "@/screens/LoadingScreens"
import { DownloadSelector } from "@/components/DownloadSelector"

// Icons
import { FiDownload } from "@/lib/icons"

// Types
import { DownloadableFileType } from "@/components/DownloadSelector/types"

/**
 * All Payouts
 * @returns
 */
export const AllPayments = () => {
	// State
	const [isPdfLoading, setIsPdfLoading] = useState(false)

	// Translations
	const t = useTrans("project")

	// PDF
	const pdfViewRef = useRef(null)
	const PDF_TITLE = t("project.revenue.all.export.title", {
		date: DateTime.local().toFormat(dateFormat),
	})
	const PDF_VIEW_WIDTH = 1920 // // Width that the table renders optimally at
	async function handlePdfDownload() {
		if (pdfViewRef.current) {
			return generatePDF(pdfViewRef.current, {
				filename: getPdfFileName(PDF_TITLE),
				overrideWidth: PDF_VIEW_WIDTH,
				compression: "FAST",
			})
		}
	}

	/**
	 * handleDownload
	 * @param type
	 */
	function handleDownload(type: DownloadableFileType) {
		if (type === DownloadableFileType.Pdf) {
			setIsPdfLoading(true)
			handlePdfDownload().then(() => {
				setIsPdfLoading(false)
			})
		}
	}

	// Return
	return (
		<>
			<Helmet>
				<title>{t("project:project.revenue.all.title")}</title>
			</Helmet>

			<Card className="mb-5 xl:w-[60%]">
				<Heading as="h5" className="mb-3 sm:truncate">
					{t("project:project.revenue.all.title")}
				</Heading>
				<p className="mb-2 whitespace-pre-wrap text-sm text-gray-500">
					{t(`project:project.revenue.all.copy`)}
				</p>

				{/** Bullet points */}
				<ul className="ml-0 list-disc whitespace-pre-wrap text-sm text-gray-500">
					<li>{t("project:project.revenue.copy.point.supplier")}</li>
					<li>{t(`project:project.revenue.copy.point.date`)}</li>

					<li>
						{t(
							`project:project.revenue.copy.point.unpaid-payments`,
						)}
					</li>
				</ul>
			</Card>
			<CardWrapper>
				<CardBody>
					{/* filters */}
					<div className="align-end mb-4 flex flex-col justify-between gap-2 md:mb-6 lg:flex-row lg:gap-0">
						<div className="space-between flex w-full flex-wrap gap-x-4 gap-y-2">
							<div className="ml-auto">
								{/** Invisible Print view */}
								<Suspense
									fallback={
										<Button
											disabled
											aria-label="Download"
											size="small"
										>
											<FiDownload className="h-4 w-4" />
										</Button>
									}
								>
									{/** Invisible Print view */}
									<PDFViewWrapper
										ref={pdfViewRef}
										title={PDF_TITLE}
										width={PDF_VIEW_WIDTH}
									>
										<PayoutsTablePrint />
									</PDFViewWrapper>

									<DownloadSelector
										options={[
											{
												name: t(
													"common:button.download.pdf",
												),
												value: DownloadableFileType.Pdf,
											},
										]}
										onDownload={handleDownload}
										disabled={isPdfLoading}
									/>
								</Suspense>
							</div>
						</div>
					</div>

					{/** Show table for web */}
					<ErrorBoundaryWithErrorState errorBoundaryClassName="mt-5">
						<Suspense fallback={<LoadingScreen />}>
							<PayoutsTableWeb />
						</Suspense>
					</ErrorBoundaryWithErrorState>
				</CardBody>
			</CardWrapper>
		</>
	)
}
