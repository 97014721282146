// React
import { ReactNode, Suspense, useContext, useEffect } from "react"
import { createPortal } from "react-dom"

// SEO
import { Helmet, HelmetProvider } from "@/lib/seo"

// Providers
import {
	AuthProvider,
	AuthInactivityIndicator,
	useAuth,
	AuthStateStatusEnum,
} from "@/context/auth"
import { RedirectProvider, RedirectContext } from "@/context/redirectContext"
import { HiddenGetParamsProvider } from "@/context/hiddenGetParams"

// React Query
import {
	queryClient,
	QueryClientProvider,
	ReactQueryDevtools,
} from "@/lib/query"

// Router
import { Navigate, Outlet, useNavigate } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Layouts
import { AuthLayout } from "@/layouts/AuthLayout"
import { NoAuthLayout } from "./layouts/NoAuthLayout"
import { LoadingScreen } from "@/screens/LoadingScreens"

// Context
import { useCurrentUserInvestmentsCount, useFeatureFlags } from "@/context/user"

// Error boundary
import { GlobalErrorBoundary } from "@/components/errors/GlobalErrorBoundary"

// Translations
import "@/i18n/browser"
import { LanguageProvider } from "@/context/lang"

// UI
import { ToastProvider, Toasts } from "@/context/toasts"

// State
import { StateProvider, useSelector } from "@/state/StateProvider"
import { AuthSliceState } from "@/state/features/authSlice"

// Environment
import { AUTH_LOGIN_URL } from "@/lib/env"

/**
 * App
 *
 * Renders all Providers and loads either <Outlet> or children
 * @returns
 */
export function App({ children }: { children?: ReactNode }) {
	return (
		<Suspense fallback={<LoadingScreen className="min-h-screen" />}>
			<StateProvider>
				<LanguageProvider>
					<ToastProvider>
						<QueryClientProvider client={queryClient}>
							<HelmetProvider>
								<GlobalErrorBoundary>
									<Helmet titleTemplate="Zonhub - %s" />

									<AuthProvider queryClient={queryClient}>
										<RedirectProvider>
											<HiddenGetParamsProvider>
												<AuthInactivityIndicator />
												<AuthContent>
													{children ?? <Outlet />}
												</AuthContent>
											</HiddenGetParamsProvider>
										</RedirectProvider>
									</AuthProvider>

									{/** When ?debug param is set, load react-query-devtools */}
									{new URL(
										window.location.href,
									).searchParams.get("debug") !== null ? (
										<ReactQueryDevtools
											initialIsOpen={false}
										/>
									) : null}
									<ModalContainer />
									<ToastContainer />
								</GlobalErrorBoundary>
							</HelmetProvider>
						</QueryClientProvider>
					</ToastProvider>
				</LanguageProvider>
			</StateProvider>
		</Suspense>
	)
}

// New component to handle auth-dependent content
function AuthContent({ children }: { children: ReactNode }) {
	// Get auth status
	const { status } = useAuth()

	// Get redirect context
	const { isRedirecting } = useContext(RedirectContext)

	return (
		<>
			{/** Show loading screen if authenticating or redirecting */}
			{(status === AuthStateStatusEnum.AUTHENTICATING ||
				isRedirecting === true) && (
				<div className="flex h-full flex-grow items-center justify-center">
					<LoadingScreen />
				</div>
			)}

			{/** Logged in */}
			{status === AuthStateStatusEnum.LOGGED_IN && (
				<AuthLayout>{children}</AuthLayout>
			)}

			{/** Logged out */}
			{status === AuthStateStatusEnum.LOGGED_OUT && (
				<NoAuthLayout>{children}</NoAuthLayout>
			)}
		</>
	)
}

/**
 * Logic to redirect to homepage
 *
 * This is the only place where this code is allowed: <Navigate to={Routes.InvestmentsProjects} />
 * @returns
 */
export function RedirectToHomePage() {
	const investmentsCount = useCurrentUserInvestmentsCount()
	// Show 'portfolio' if we have investments, otherwise show Platform stats
	return investmentsCount > 0 ? (
		<Navigate to={Routes.InvestmentsProjects} />
	) : (
		<Navigate to={Routes.StatisticsPlatform} />
	)
}

/**
 * RedirectToLogin
 *
 * This is the only place this code is allowed: to={Routes.Login} / window.location.href = `${AUTH_LOGIN_URL}?next=${location.href}`
 * @returns
 */
export function RedirectToLogin() {
	// Feature flags
	const { getFeatureFlagValue } = useFeatureFlags()

	// State
	const authToken = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.token,
	)
	const { redirect } = useContext(RedirectContext)
	const navigate = useNavigate()

	// Either redirect to Horizon cookie-based login or to dashboard token-based login
	useEffect(() => {
		if (
			getFeatureFlagValue("ENABLE_DASHBOARD_TOKEN_LOGIN") === true &&
			authToken === null
		) {
			navigate(Routes.Login)
		} else {
			window.location.href = `${AUTH_LOGIN_URL}?next=${location.href}`
		}
	}, [getFeatureFlagValue, authToken, redirect])

	// Loadingscreen
	return <LoadingScreen />
}

function ModalContainer() {
	return createPortal(
		<div />,
		document.getElementById("modals") ?? document.body,
	)
}

function ToastContainer() {
	return createPortal(
		<div className="fixed bottom-0 right-0 z-[160] flex flex-col items-end gap-4 pb-4 pr-4 sm:pb-6 sm:pr-6">
			<Toasts />
		</div>,
		document.getElementById("toasts") ?? document.body,
	)
}
