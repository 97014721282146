// Router
import { useNavigate } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Translate
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Dates
import { DateTime } from "@/lib/dates"

// General UI
import { Card, CardWrapper } from "@/components/Card"
import { DownloadSelector } from "@/components/DownloadSelector"
import { Heading } from "@/components/Typography"
import { Select } from "@/components/Select"

// Types
import { DownloadableFileType } from "@/components/DownloadSelector/types"

/**
 * useLastTwelveMonths
 * @returns
 */
function useLastTwelveMonths() {
	const firstMonth = DateTime.now().minus({ months: 1 }) // Start from previous month
	const months = []

	for (let i = 0; i < 12; i++) {
		const date = firstMonth.minus({ months: i })
		// Format as YYYY-MM using Luxon's built-in formatting
		const monthStr = date.toFormat("yyyy-MM")
		months.push(monthStr)
	}

	return months
}

/**
 * IntroHeader
 * @returns
 */
interface IntroHeaderProps {
	displayDownloadButton?: boolean
	fullName?: string | null
	endTime: DateTime
	onDownload?: (type: DownloadableFileType) => void
	downloadOptions?: { name: string; value: string }[]
	totalProjectCount: number
	totalInvestmentValue: string
}
export function IntroHeader({
	displayDownloadButton = true,
	fullName,
	endTime,
	onDownload = () => {},
	downloadOptions = [],
	totalProjectCount,
	totalInvestmentValue,
}: IntroHeaderProps) {
	// Translate
	const t = useTrans(["investments", "dashboard"])
	const { formatCurrency } = useLang()

	// Router
	const navigate = useNavigate()

	// Dates
	const monthOptions = useLastTwelveMonths().map((date) => ({
		key: date,
		value: DateTime.fromFormat(date, "yyyy-MM").toLocaleString({
			month: "long",
			year: "numeric",
		}),
	}))

	// Template
	return (
		<CardWrapper>
			<Card>
				<div className="flex flex-col gap-2 md:flex-row">
					<Heading as="h2" styleAs="h5">
						{t("investments:investments.monthly_overview.intro", {
							fullName,
							month: endTime.toLocaleString({
								month: "long",
							}),
						})}
					</Heading>

					{/** Filters */}
					<div className="ml-auto flex gap-2">
						<Select
							value={endTime.toFormat("yyyy-MM").toString()}
							onChangeValue={(year) => {
								year = year || String(DateTime.local().year)
								navigate(
									Routes.InvestmentsProjectsMonthlyOverview.replace(
										":date",
										year,
									),
								)
							}}
							label={
								<>
									{endTime.toLocaleString({
										month: "long",
										year: "numeric",
									})}
								</>
							}
							options={monthOptions}
							variant="transparent"
						/>

						{/** Download button */}
						{displayDownloadButton === true ? (
							<div className="ml-auto">
								<DownloadSelector
									options={downloadOptions}
									onDownload={onDownload}
								/>
							</div>
						) : null}
					</div>
				</div>

				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:mt-6">
					{/** Project Total */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"dashboard:dashboard.stats.platform_stats.blocks.total_projects",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{totalProjectCount}
						</dd>
					</div>

					{/** Current total value */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"investments:investments.fiscal.hero.returns_today.title",
							)}
						</dt>
						<dd className="mt-1 flex text-sm text-gray-900">
							{formatCurrency(
								totalInvestmentValue
									? parseFloat(totalInvestmentValue)
									: 0,
							)}
						</dd>
					</div>
				</div>
			</Card>
		</CardWrapper>
	)
}
